.homeLink {
  z-index: 999;
  margin-bottom: 1px;
}

.homeLink:hover {
  border-bottom: 1px solid;
  margin-bottom: 0
}

.homeVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videoBox {
  position: fixed;
  right: 0;
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100vh;
  height: 100%;
  padding: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #111;
  /* z-index: -1; */
}

.videoBox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(0deg, #08a015, #33d0ff); */
  opacity: .9;
  /* mix-blend-mode: hard-light; */
}

.profileImage {
  margin-top: 22px;
  text-align: center;
  width: 100%;
}

.profileImage img {
  /* width: 8em;
  height: 8em; */
  border-radius: 17%;
  border: 1px solid #333 !important;
  box-shadow: rgb(28 32 93 / 71%) 0px 0px 1px 0px;
}

/* .videoBoxDark::after {
  mix-blend-mode: exclusion;
} */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* 
.bg {
  background-image: url('/heatmap_parks_bg_0.jpg');
  background-size: cover;
  background-blend-mode: luminosity;
  background-color: aquamarine;
} */
